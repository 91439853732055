import React from 'react'
import styled from "styled-components"
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons'
import ErrorMessage from "components/common/errorMessage"

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
`

const StyledProfile = styled.div`
  width: 50%;
  flex: 0 1 50%;
  padding: 0.5rem;
  @media (max-width: 1200px) {
    width: 100%;
    flex: 1 1 100%;
  }
  .profile {
    height: 100%;
    width: 100%;
    background: #F8FBFF;
    border: 1px solid #CBD5E0;
    border-radius: 0.25rem;
    .profile__header {
      padding: 1rem;
      display: flex;
      .avatar {
        margin-right: 1rem;
      }
      .location {
        font-size: 0.8rem;
        line-height: 1rem;
        color: #1DAFB6;
        margin-bottom: 0.5rem;
      }
      .name {
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #1A212D;
        max-width: 12rem;
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          max-width: 40rem;
        }
      }
    }
    .profile__body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
      background: white;
      border-top: 1px solid #CBD5E0;
      border-bottom: 1px solid #CBD5E0;
      color: #0B2540;
      &:last-of-type {
        border-top: none;
      }
      .item {
        font-size: 0.8rem;
        line-height: 1rem;
        color: #0B2540;
        margin-bottom: 0.5rem;
        &:last-of-type {
          margin-bottom: 0;
        }
        span {
          margin-left: 0.7rem;
        }
      }
      .clamp {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      .url {
        svg {
          margin-left: 0.5rem;
          color: #1DAFB6;
        }
      }
    }
    .profile__footer {
      padding: 1rem;
      height: unset;
      max-height: unset;
      color: #0B2540;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        height: 7.9375rem;
        max-height: 7.9375rem;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
      }
    }
  }
`

const List = (props) => {
  console.log('props.profile', props.profile)
  if (props.profiles.length) {
    return (
      <StyledWrapper>
        {
          props.profiles.map(profile => {
            const name = profile.info.full_name
            const location = profile.info.location.text
            const summary = profile.info.summary
            const type = profile.tags.filter(tag => tag.name === 'type')[0].value
            const category = profile.tags.filter(tag => tag.name === 'category')[0].value
            const workingFrom = profile.tags.filter(tag => tag.name === 'working-type')[0].value
            const linkedin = profile.info.urls.linkedin
            const instagram = profile.info.urls.instagram
            const position = profile.experiences[0].title

            return (
              <StyledProfile key={profile.key}>
                <div className="profile">
                  <div className="profile__header">
                    <div className="avatar">
                      <Avatar
                        name={name}
                        round={true}
                        size={50}
                      />
                    </div>
                    <div>
                      <div className="name ellipsis">
                        {name}
                      </div>
                      <div className="location ellipsis">
                        {location}
                      </div>
                    </div>
                  </div>
                  <div className="profile__body">
                    <div>worked as {type}</div>
                    <div className="clamp">{position}<span className="textLight"> ({category})</span></div>
                    <div>{location}<span className="textLight"> (open to {workingFrom})</span></div>
                  </div>
                  <div className="profile__body">
                    <a href={linkedin} target="_blank" className="url">Linkedin<FontAwesomeIcon  icon={faExternalLinkAlt} /></a>
                    <a href={instagram} target="_blank" className="url">Instagram<FontAwesomeIcon  icon={faExternalLinkAlt} /></a>
                  </div>
                  <div className="profile__footer"><span>{summary}</span></div>
                </div>
              </StyledProfile>
            )
          })
        }
      </StyledWrapper>
    )
  }
  return (
    <ErrorMessage
      title="No profile found!"
      subtitle="Please try adjusting your criterias"
      type="empty"
      min="true"
    /> 
  )

}

export default List;