import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Select from 'react-select'
import Wart from "components/icons/wart"
import List from "./list"
import Spinner from 'components/common/spinner'
import ErrorMessage from "components/common/errorMessage"

const StyledContainer = styled.div`
  width: unset;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 52.31rem;
  }
`
const StyledWrapper = styled.div`
  background: white;
  min-height: 20rem;
  border-radius: 0.62rem;
  border-top: 0.3125rem solid ${props =>props.color};
  margin-bottom: 2rem;
  .form__body {
    padding: 1.5rem;
    .label {
      color: ${({ theme }) => theme.colors.zodiacLight};
      font-size: 1.25rem;
      line-height: 2rem;
      margin-bottom: 0.55rem;
    }

    .subtitle {
      color: ${({ theme }) => theme.colors.tertiary};
      font-size: 0.9rem;
      line-height: 1.5rem;
      margin-bottom: 1.2rem;
    }
    .alert {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        a:first-of-type {
          margin-right: 1rem;
        }
        margin-top: 0.7rem;
      }
      .logo {
        height: 3.56rem;
        width: 3.56rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E7F0FF;
        border-radius: 0.22rem;
        margin-bottom: 1rem;
      }
    }
  }
  .form__footer {
    padding: 1.3rem 1.5rem;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #EBEFF9;
  }
  .jobs {
    padding: 1.7rem;
    .label {
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: #183D63;
      margin-bottom: 1.7rem;
    }
  }
`
const StyledButton = styled.button`
  padding: 0 1.3rem;
  height: 3.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 0.375rem;
  background: #22B5F3;
  border: none;
  cursor: pointer;
`

const Filters =  styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .control {
    &:nth-of-type(2) {
      margin: 0;
    }
    margin-bottom: 1rem;
    flex: 1 1 100%;
    width: 100%;
    .label {
      color: ${({ theme }) => theme.colors.dodger};
      font-size: 0.9rem;
      line-height: 1.1rem;
    }
    .select__control {
      background: #F9FCFF;
      border: 1px solid #CBD5E0;
      border-radius: 0.25rem;
      color: #183D63;
      font-size: 0.9rem;
      line-height: 1.1rem;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .control {
      flex: 1 1 30%;
      width: 30%;
      &:nth-of-type(2) {
        margin: 0 1rem 1rem 1rem;
      }
      margin-bottom: 1rem;
    }
  }
`

const MoreFilters = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    span {
      white-space: nowrap;
      cursor: pointer;
      text-align: center;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      background: #F9FCFF;
      border: 1px solid #CBD5E0;
      box-sizing: border-box;
      border-radius: 6.25rem;
      color: #183D63;
      font-size: 0.9rem;
      line-height: 1.1rem;
      margin-right: 1rem;
      margin-bottom: 0.5rem;
      &:hover {
        border: 1px solid #22B5F3!important;
        color: #22B5F3;
      }
    }
    .active {
      border: 1px solid #22B5F3!important;
      color: #22B5F3;
    }
`
const locations = [
  {
    label: 'All locations',
    value: {}
  },
  {
    label: 'Dublin',
    value: {"lat":53.34807,"lng":-6.2482699999999}
  },
  {
    label: 'Paris',
    value: {"lat": 48.85717, "lng": 2.3414}
  },
  {
    label: 'London',
    value: {"lat":51.50740836,"lng":-0.12769869299996}
  },
  {
    label: 'San Francisco',
    value: {"lat":37.77712,"lng":-122.41964}
  },
  {
    label: 'Barcelona',
    value: {"lat":41.38804,"lng":2.17001}
  },
  {
    label: 'Portland',
    value: {"lat":45.51179, "lng":-122.67563}
  }
]

const functions = [
  {
    label: 'All functions',
    value: ''
  },
  {
    label: 'Design',
    value: 'Design'
  },
  {
    label: 'Business Development',
    value: 'Business Development'
  },
  {
    label: 'Customer Service',
    value: 'Customer Service'
  },
  {
    label: 'Management',
    value: 'Management'
  },
  {
    label: 'Policy',
    value: 'Policy'
  },
  {
    label: 'Accounting / Auditing',
    value: 'Accounting / Auditing'
  }
]

const types = [
  {
    label: "All contract types",
    value: ''
  },
  {
    label: 'Worked as full-time',
    value: 'full-time'
  },
  {
    label: 'Worked as contractor',
    value: 'contractor'
  },
]

const Searching = (props) => {
  const [tags, setTags] = useState([])
  const [workingFrom, setWorkingFrom] = useState('')
  const [location, setLocation] = useState({})
  useEffect(() => {
    let apiTags = [];
    if (workingFrom) {
      apiTags = [...tags].filter(tag => tag[0].name !== 'working-type')
      apiTags.push([{name: 'working-type', value: workingFrom}])
      setTags(apiTags)
    } else {
      apiTags = [...tags].filter(tag => tag[0].name !== 'working-type')
      setTags(apiTags)
    }
    props.getProfileSearching({
      tags: apiTags,
      location,
    })
  }, [workingFrom])

  const changeLocation = value => {
    setLocation(value.value)
    props.getProfileSearching({
      location: value.value,
      tags,
    })
  }

  const changeFunction = value => {
    let apiTags = [...tags].filter(tag => tag[0].name !== 'category')
    if (value.value) apiTags.push([{ name: 'category', value: value.value }])
    setTags(apiTags)
    props.getProfileSearching({
      tags: apiTags,
      location,
    })
  }

  const changeType = value => {
    let apiTags = [...tags].filter(tag => tag[0].name !== 'type')
    if (value.value) apiTags.push([{ name: 'type', value: value.value }])
    setTags(apiTags)
    props.getProfileSearching({
      tags: apiTags,
      location,
    })
  }
  console.log('profile', props.profiles)
  return (
    <StyledContainer
      color="#22B5F3"
    >
      <StyledWrapper>
        <div className="form__body">
          <div className="label">
            Select criterias
          </div>
          <div className="subtitle">
            Talent Pool/Marketplace Search Demo powered by HrFlow.ai Profile Searching API.
          </div>
          <Filters>
            <div className="control">
              <label className="label">Location</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                onChange={changeLocation}
                defaultValue={locations[0]}
                isClearable={false}
                isSelectable={false}
                isRtl={false}
                options={locations}
              />
            </div>
            <div className="control">
              <label className="label">Function</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                onChange={changeFunction}
                defaultValue={functions[0]}
                isClearable={false}
                isSelectable={false}
                isRtl={false}
                options={functions}
              />
            </div>
            <div className="control">
              <label className="label">Contract</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={types[0]}
                onChange={changeType}
                isClearable={false}
                isSelectable={false}
                isRtl={false}
                options={types}
              />
            </div>
          </Filters>
          <MoreFilters>
            <span
              className={workingFrom === 'relocation' ? 'active': ''}
              onClick={() => workingFrom === 'relocation' ? setWorkingFrom('') : setWorkingFrom('relocation')}
            >
              Open to relocation
            </span>
            <span
              className={workingFrom === 'remote' ? 'active' : '' }
              onClick={() => workingFrom === 'remote' ? setWorkingFrom('') : setWorkingFrom('remote')}
            >
              Open to remote
            </span>
          </MoreFilters>
        </div>
        <div className="form__footer">
            <StyledButton
              Search
            >
              <span className="mr-1">Search</span><Wart color="#FFF" />
            </StyledButton>
          </div>
      </StyledWrapper>
      <StyledWrapper>
        <div className="jobs">
          <div className="label">Profiles</div>
          {props.profiles?.r && (
              <Spinner />
            )}
            {props.profiles?.s && (
              <List  profiles={props.profiles.payload.profiles} />
            )}
            {props.profiles?.f && (
              <ErrorMessage
                title="Something went wrong"
                subtitle="Please retry again later."
                type="error"
                min="true"
              />
            )}
        </div>
      </StyledWrapper>
    </StyledContainer>
  )
}


export default Searching